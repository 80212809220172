import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import CloudServiceAddPage from "./CloudServiceAddPage";
import {message} from "antd";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Col, Row, Tooltip, Typography} from "antd";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";
import DeleteIcon from "../../components/CustomIcons/DeleteIcon";
import EditPencilIcon from "../../components/CustomIcons/EditPencilIcon";


const CloudServiceListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (item)=>{
      let content = <Row className={'cardHeader'} style={{alignItems:'center'}} key={'cardHeader'}>
        <Tooltip title={item? item[config.cardTitleField]: ''} placement={'bottomLeft'}>
            <Col key={'cardTitle'} span={16} style={{whiteSpace: 'nowrap', fontWeight: 'bold', fontSize:'20px', overflow:'hidden', textOverflow:'ellipsis'}}>{item? item[config.cardTitleField]: ''}</Col>
        </Tooltip>
        <Col key={'cardButtons'} span={8} style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
            {!config.hideEdit ?
                <PermissionChecker requiredPermissions={config.editPermission || []}>
                    <Button className={'card-icon card-edit-icon'} icon={<EditPencilIcon />} style={{border: 'none'}}
                     onClick={()=>handleOpenModal(item, true)}/>
                </PermissionChecker> : <div></div>}
            {!item.has_partner ?
                <CustomConfirmPopup
                    title={config.deleteTitle || "Are you sure to delete this instance?"}
                    onConfirm={()=>handleDeleteClick(item)}
                    icon={<DeleteIcon/>}
                    okText={t('yes_text')}
                    cancelText={t('no_text')}
                    okType='default'
                >
                    <PermissionChecker requiredPermissions={config.deletePermission || []}>
                        <Button
                        className={'card-icon card-edit-icon'}
                        icon={<DeleteIcon />}
                        style={
                            {
                                border: 'none',
                                color:item.has_partner? 'grey': '#377EF3'
                            }
                            }
                        />
                    </PermissionChecker>
                </CustomConfirmPopup> : null}
        </Col>
    </Row>;
    return content;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return null;
  }

  const config = {
    url: '/api/v1/auth/cloud_services/',
    pageTitle: t('cloud_service_list_title'),
    pageSubtitle: t('cloud_service_list_subtitle'),
    deleteTitle: t('cloud_service_delete_confirmation_text'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {
        'name': t('cloud_services_name_field'),
        'admin_name': t('cloud_services_admin_name_field'),
        'email': t('cloud_services_email_field'),
        'mobile': t('cloud_services_mobile_field'),
        'service_category': t('cloud_services_category_field'),
        'service_item': t('cloud_service_item_field'),
    },
    cardFooterFields: [],
    orderingFields: [
            {id: '-name', name: t('cloud_services_orderby_field1')},
            {id: 'name', name: t('cloud_services_orderby_field2')},
    ],
    disableDelete: true,
    addPermission: ['cloud-service-create'],
    editPermission: ['cloud-service-edit'],
    deletePermission: ['cloud-service-delete'],
    viewPermission: ['cloud-service-detail'],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
  }
  const handleOpenModal = (item, edit) => {
    if(edit){
      setEdit(true);
      setItem(item);
      setShowModal(true);
    }
    else {
      setEdit(false);
      setItem(null);
      setShowModal(true);

    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEdit(false);
    setItem(null);
  };
  const handleSuccessAdd = () =>{
    setShowModal(false);
    setEdit(false);
    setItem(null);
    setReload(!reload);
  }

  const handleDeleteClick = (item)=>{
    if(item.has_partner){
            message.error(t('cloud_service_delete_validation_text'))
        }
        else {
            http.delete(`/api/v1/auth/cloud_services/${item.id}/delete/`).then((resp) => {
                setReload(!reload);
            }).catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
        }
  }

  const handleDetailClick=(item)=>{
    navigate('/cloud_services/detail/', { state: {cloud_service: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
        <CloudServiceAddPage
            visible={showModal}
            onClose={handleCloseModal}
            onSuccessSubmit={handleSuccessAdd}
            edit={edit}
            editItem={item}
            reload={reload}
        />
      </>
  );
};

export default CloudServiceListPage;
