import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";

const PartnerAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem})=>{
    const [initialValues, setInitialValues] = useState({})
    const { t } = useTranslation();
    const config = {
        addUrl: '/api/v1/partners/add/',
        editUrl: edit ? `/api/v1/partners/${editItem?.id}/edit/` : '',
        dataUrl: edit ? `/api/v1/partners/${editItem?.id}/` : '',
        pageTitle: !edit ? t('partner_addpage_title') : t('addpage_edit_button_text') + ` ${editItem?.company_name}`,
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? t('partner_add_successful_message') : t('partner_edit_successful_message'),
        modalSize: 600,
        fields: [
            {
                name: 'company_name',
                label: t('partner_addpage_namefield'),
                placeholder: t('partner_addpage_namefield_label'),
                isRequired: true,
                requiredMessage: t('partner_name_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 1,
                hidden: initialValues.status
            },
            {
                name: 'email',
                label: t('partner_addpage_email_field'),
                placeholder: t('partner_addpage_email_field_placeholder'),
                isRequired: true,
                requiredMessage: t('partner_email_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 2
            },
            {
                name: 'contact_no',
                label: t('partner_addpage_contact_field'),
                placeholder: t('partner_addpage_contact_field_placeholder'),
                isRequired: true,
                requiredMessage: t('partner_contact_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 3
            },
            {
                name: 'address_1',
                label: t('partner_addpage_address1_field'),
                placeholder: t('partner_addpage_address1_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                colNo: 1,
                order: 4
            },
            {
                name: 'address_2',
                label: t('partner_addpage_address2_field'),
                placeholder: t('partner_addpage_address2_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                colNo: 1,
                order: 5
            },
            {
                name: 'first_name',
                label: t('partner_addpage_contact_person_firsname_field'),
                placeholder: t('partner_contactperson_field'),
                isRequired: true,
                requiredMessage: t('contact_person_required_text'),
                colNo: 2,
                order: 1
            },
            {
                name: 'remote_username',
                label: t('partner_addpage_remoteusername_field'),
                placeholder: t('partner_addpage_remoteusername_field_placeholder'),
                isRequired: true,
                requiredMessage: t('partner_remoteusername_field_required_text'),
                colNo: 2,
                order: 3,
                hidden: initialValues.status
            },
            {
                name: 'remote_password',
                label: t('partner_addpage_remotepassword_field'),
                placeholder: t('partner_addpage_remotepassword_field_placeholder'),
                type: 'password',
                isRequired: true,
                requiredMessage: t('partner_remotepassword_field_required_text'),
                colNo: 2,
                order: 4,
                hidden: initialValues.status
            },
            {
                name: 'assigned_to',
                label: t('partner_addpage_manageby_field'),
                placeholder: t('select_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                extraProps: {
                    url: '/api/v1/auth/users/combo/'
                },
                colNo: 2,
                order: 5
            },
            {
                name: 'cloud_service',
                label: t('partner_cloud_service_field'),
                placeholder: t('select_placeholder'),
                isRequired: true,
                requiredMessage: t('cloud_service_required_text'),
                type: 'select',
                extraProps: {
                    url: '/api/v1/auth/cloud_services/combo/'
                },
                colNo: 2,
                order: 6
            },
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/partners/${editItem?.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    return <AddPageModal config={config} visible={visible} onClose={onClose} onSuccessSubmit={onSuccessSubmit} onErrorSubmit={onErrorSubmit} edit={edit} initialValues={initialValues} />

}

export default PartnerAddPage;